import { SelectionChangedEvent } from "ag-grid-community";
import { GeneralProposalEntity } from "domain/entity/GeneralProposal/GeneralProposalEntity";
import { EMPTY_GENERAL_PROPOSAL_SEARCH_CRITERIA } from "domain/entity/GeneralProposal/GeneralProposalSearchCriteria";
import moment from "moment";
import { INITIAL_GENERAL_PROPOSAL_COL_DEF, transferRowData } from "presentation/constant/GeneralProposal/GeneralProposalColumnDefinition";
import { GeneralProposalConstant } from "presentation/constant/GeneralProposal/GeneralProposalConstant";
import { useGeneralProposalVM } from "presentation/hook/GeneralProposal/useGeneralProposalVM";
import { useGeneralProposalTracked } from "presentation/store/GeneralProposal/GeneralProposalProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Core } from "veronica-ui-component";
import { GroupButtons, HPHTable, Loader } from "veronica-ui-component/dist/component/core";


const GeneralProposalTablePanel:React.FC = () => {
    const [generalProposalState] = useGeneralProposalTracked();
    const generalProposalVM = useGeneralProposalVM();
    const gridRef: any = useRef(null);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const {searchStatus,generalProposalList,isRejected,isRejectedCount,isPendingApproval,isPendingApprovalCount} = generalProposalState;
    const GENERAL_PROPOSAL_CONSTANT = GeneralProposalConstant.Table;
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    
    useEffect(() => {
        if (onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_GENERAL_PROPOSAL_COL_DEF.slice());
        
        // if (!generalProposalState.selectedRows ||
        //     generalProposalState.selectedRows.length <= 0) {        
            gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        // }
    })


    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        generalProposalVM.updateSelectedRows(selectedRows);
      }, [generalProposalVM])

      const handleRowDoubleClick = useCallback((entity: GeneralProposalEntity) => {
          generalProposalVM.onRowDoubleClick(entity);
          generalProposalVM.searchGeneralProposalItem(entity).then((data) => {
            setIsLoading(false);
        }).catch(error => {            
            setIsLoading(false);
        }).finally(() => {
            setIsLoading(false);
        })
    }, [generalProposalVM])

    const handleGroupButtonClick = useCallback(async (buttonId: string) => {
        generalProposalVM.onShowLoading();
        if(searchStatus && buttonId === searchStatus){
            let newSearchCriteria = null;
            newSearchCriteria = {...EMPTY_GENERAL_PROPOSAL_SEARCH_CRITERIA} 
            newSearchCriteria = { ...newSearchCriteria, 
                effectiveDateFrom: moment().subtract(2, "years").startOf("year").toDate(),
                effectiveDateTo: moment().endOf('year').endOf('day').toDate(), 
            };
            await generalProposalVM.searchAllGeneralProposal();
            await generalProposalVM.searchGeneralProposal(newSearchCriteria).then((data) => {            
                generalProposalVM.onHideLoading();
            }).catch(error => {            
                generalProposalVM.onHideLoading();
            }).finally(() => {
                generalProposalVM.onHideLoading();
            })
        }else{
            await generalProposalVM.onGroupButtonClick(buttonId).then((data) => {
                generalProposalVM.onHideLoading();
            }).catch(error => {            
                generalProposalVM.onHideLoading();
            }).finally(() => {
                generalProposalVM.onHideLoading();
            })
        }

    }, [searchStatus, generalProposalVM]);
    
    const template = useMemo(() => {
        return(
        <>
          {/* <Core.CheckButton
            checked={isAll}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleGroupButtonClick(e.target.id) }}
            showBadge={true}
            badgeValue={isAllCount>999?'999+':isAllCount}
            disabled={isAllCount===0}
            size="medium"
            inputId="isAll"
            label={GENERAL_PROPOSAL_CONSTANT.ALL_BUT} 
          /> */}
          <Core.CheckButton
            checked={isRejected}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleGroupButtonClick(e.target.id) }}
            showBadge={true}
            badgeValue={isRejectedCount>999?'999+':isRejectedCount}
            disabled={isRejectedCount===0}
            size="medium"
            inputId="isRejected"
            label={GENERAL_PROPOSAL_CONSTANT.REJECTED_BUT} 
          />
          <Core.CheckButton
            checked={isPendingApproval}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleGroupButtonClick(e.target.id) }}
            showBadge={true}
            badgeValue={isPendingApprovalCount>999?'999+':isPendingApprovalCount}
            disabled={isPendingApprovalCount===0}
            size="medium"
            inputId="isPendingApproval"
            label={GENERAL_PROPOSAL_CONSTANT.PENDING_APPROVAL_BUT} 
          />
      </>
  )},[GENERAL_PROPOSAL_CONSTANT.PENDING_APPROVAL_BUT, GENERAL_PROPOSAL_CONSTANT.REJECTED_BUT, handleGroupButtonClick, isPendingApproval, isPendingApprovalCount, isRejected, isRejectedCount])
  
    const memoGeneralProposalTable = useMemo(() => {

        return (
        <HPHTable
            id='general-proposal-table'
            isNewColumnSetting={true}
            columns={INITIAL_GENERAL_PROPOSAL_COL_DEF.slice()}
            data={transferRowData(generalProposalList??[])}
            showPaginator={false}
            editable={false}
            showAddIcon={false}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            rowSelection={"multiple"}
            isRowHighligted={true}
            onSelectionChanged={handleSelectionChange}
            onRowDoubleClick={(e: any, entity: GeneralProposalEntity) => handleRowDoubleClick(entity)}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)" 
            ref={gridRef}
            headerLabel={
                <GroupButtons
                    alignment="single-selection"
                    errorMessage=""
                    label=""
                    children={template}
                    />
            }
        />
        );
    },[handleRowDoubleClick, handleSelectionChange, generalProposalList, template])

    return <><TableWrapper>
        {(generalProposalState.isLoading || isLoading) && <Loader Indicator="Spinner" size="Medium" /> }
            {memoGeneralProposalTable}
        </TableWrapper>
        </>;
}

export default memo(GeneralProposalTablePanel);
