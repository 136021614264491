import { useGeneralProposalTracked } from "presentation/store/GeneralProposal/GeneralProposalProvider";
import { memo, useMemo } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import GeneralProposalTablePanel from "../Table/GeneralProposalTablePanel";
import TariffItemTierTablePanel from "./TariffItemTierTablePanel";

const GeneralProposalRightPanel = () => {
    const [generalProposalState] = useGeneralProposalTracked();
    // const generalProposalVM = useGeneralProposalVM();
    const {isShowTariffItemTierPanel} = generalProposalState;

    const memoGeneralProposalTable = useMemo(() => {
        return <GeneralProposalTablePanel/>
    },[])

    // const memoTariffItemRightTable = useMemo(() => {
    //     return <TariffItemRightPanel/>
    // },[])

    const memoTariffItemTierTablePanel = useMemo(() => {
        return <TariffItemTierTablePanel/>
    },[])

    return <>
        <div className={`main-comp-wrapper${(isShowTariffItemTierPanel) ? '' : ' im-hide-side-form-draggable'}`}>
                    
            <SliderPanel
                isOpen={true}
                draggable={true}
                leftSectionWidth={isShowTariffItemTierPanel?"40%":"100%"}
                rightSectionWidth={isShowTariffItemTierPanel?"60%":"0%"}
                leftChildren={memoGeneralProposalTable}
                rightChildren={memoTariffItemTierTablePanel}
            />
        </div>
    </>
}

export default memo(GeneralProposalRightPanel);