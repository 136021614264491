import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { GeneralProposalConstant } from "./GeneralProposalConstant";

const GENERAL_PROPOSAL_CONSTANT = GeneralProposalConstant.Table;
let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];
export const INITIAL_GENERAL_PROPOSAL_COL_DEF: any[] = [
    {
        headerName: GENERAL_PROPOSAL_CONSTANT.SEQ_NO,
        field: 'seq',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        // pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        filter: false,
        width: 120,
    },
    {
        headerName: GENERAL_PROPOSAL_CONSTANT.TARIFF_TYPE,
        field: 'tariffType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: GENERAL_PROPOSAL_CONSTANT.TARIFF_CODE,
        field: 'tariffCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: GENERAL_PROPOSAL_CONSTANT.CURRENCY,
        field: 'currencyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,
    },
    {
        headerName: GENERAL_PROPOSAL_CONSTANT.EFFECTIVE_DATE,
        field: 'effectiveDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
        dataType: "date"
    },
    {
        headerName: GENERAL_PROPOSAL_CONSTANT.EXPIRY_DATE,
        field: 'expiryDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
        dataType: "date"
    },
    {
        headerName: GENERAL_PROPOSAL_CONSTANT.TARIFF_CODE_DESC,
        field: 'tariffCodeDesc',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 380,
    },
    {
        headerName: GENERAL_PROPOSAL_CONSTANT.CONFIRMED_DATE,
        field: 'confirmedDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
        dataType: "date"
    },
    {
        headerName: GENERAL_PROPOSAL_CONSTANT.STATUS,
        field: 'status',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,
    },
    {
        headerName: GENERAL_PROPOSAL_CONSTANT.REMARKS,
        field: 'remarks',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 380,
    },
    {
        headerName: GENERAL_PROPOSAL_CONSTANT.REJECT_REASON,
        field: 'rejectReason',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 380,
    },

].map((col, index) => {
    const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data:any[]) => {
    const externalFnctions:{[key:string]:((fieldName:string, row:any) => {})} = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}
